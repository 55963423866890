import { Link } from 'gatsby';
import React from 'react';

import { PostPreview } from '../../types/post';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import AspectBox from '../AspectBox';
import Img from '../Img';

import './PostList.scss';

const PostList: React.FC<{
  posts: PostPreview[];
}> = ({ posts }) => (
  <div className="post-list">
    {posts.map((post) => (
      <div key={post.path} className="post">
        <Link to={post.path}>
          <ul>
            {post.mainPhotos.map((photo) => (
              <li key={photo}>
                <AspectBox heightRatio={2 / 1}>
                  <Img src={buildImgixUrlWithAspectRatio(photo, 2 / 1, 300)} />
                </AspectBox>
              </li>
            ))}
          </ul>

          <h2>
            {post.title}
          </h2>
        </Link>
      </div>
    ))}
  </div>
);

export default PostList;

import { PageProps } from 'gatsby';
import React from 'react';

import Layout from '../../components/Layout';
import PostList from '../../components/PostList';
import { PostPreview } from '../../types/post';

import './Posts.scss';

export interface PostsPageContext {
  posts: PostPreview[];
}

const Posts: React.FC<PageProps<unknown, PostsPageContext>> = ({ pageContext: { posts } }) => (
  <Layout
    mainClassName="posts"
    title="Idéias e Inspirações de Experiências Incríveis"
  >
    <div className="posts-heading">
      <div className="container">
        <h1>
          <span>Ideias & Inspirações</span>
        </h1>
      </div>
    </div>

    <div className="container">
      <PostList posts={posts} />
    </div>
  </Layout>
);

export default Posts;
